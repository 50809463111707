<template>
  <div
    class="course_wrapper w"
    :style="{'padding-bottom':componentInfo.data.length ? '10px':'25px'}"
  >
    <div
      v-if="componentInfo.config.showTitle"
      class="course_title"
    >
      <div class="course_title_name">
        <index-title-icon />
        <span>{{ componentInfo.config.title }}</span>
      </div>
      <NuxtLink to="/course/list">
        <div
          v-if="componentInfo.config.showMore"
          class="course_title_more pointer"
        >
          更多<i class="arrows" />
        </div>
      </NuxtLink>
    </div>
    <div class="course_item_wrapper clearfix">
      <template v-if="componentInfo.config.showType !== 1">
        <custom-link
          :to="handleNavigate(componentInfo.config)"
          :target-type="componentInfo.config.targetType"
          :target-open-new-page="componentInfo.config.targetOpenNewPage"
        >
          <div
            class="course_item_ad course_item fl"
            :style="{ height: componentInfo.config.showType === 2 ? '219px' : '453px' }"
          >
            <div class="course_item_content">
              <div
                v-if="componentInfo.config.imageUrl"
                class="item_image_wrapper"
              >
                <img
                  class="item_image"
                  :src="componentInfo.config.imageUrl"
                >
              </div>
            </div>
          </div>
        </custom-link>
      </template>
      <NuxtLink
        v-for="item in componentInfo.data"
        :key="item.id || item.goodsId"
        :to="toUrl(item)"
      >
        <div class="course_item fl">
          <div class="course_item_content">
            <div
              v-if="item.courseCover || item.cover"
              class="item_image_wrapper"
            >
              <img
                class="item_image"
                :src="(item.courseCover || item.cover)"
              >
              <div class="course_type">
                {{ courseTypeEnum[item.courseType || item.goodsType] }}
              </div>
            </div>
            <div class="course_desc">
              <div
                class="item_text tx-ov-2"
                v-html="item.courseName || item.goodsName"
              />
              <div class="course_detail">
                <div class="price">
                  <span
                    v-if="componentInfo.config.isVipModal"
                    class="current_price"
                    :class="{'free': !item.vipPrice}"
                  >{{ item.vipPrice || '免费' }}</span>
                  <span
                    v-else
                    class="current_price"
                    :class="{'free': item.price == 0}"
                  >{{ item.price || '免费' }}</span>
                  <span
                    v-if="item.linePrice != 0"
                    class="old_price"
                  >{{ item.linePrice }}</span>
                </div>
                <div v-if="(shopData?.config?.showLearnUserCount && shopData?.config?.showCourseLearnUserCount && item.courseType)">
                  <div
                    v-if="item.courseType === 6"
                    class="course_sale"
                  >
                    {{ (item.saleCount || 0) + '人已购买' }}
                  </div>
                  <div
                    v-else
                    class="course_sale"
                  >
                    {{ (item.studyNum || 0) + '人已学习' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NuxtLink>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref } from 'vue'
import { useUserStore } from '@/stores/user'
export default defineComponent({
  props: {
    componentInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  setup (props, { emit }) {
    const { getEnumList, shopData } = useUserStore()
    const courseTypeEnum = ref({
      1: '直播课',
      2: '视频课',
      3: '音频课',
      4: '图文课',
      5: '专题课',
      6: '套餐'
    })
    onMounted(() => {
      getEnumList('CourseTypeEnum', 'obj').then(res => {
        courseTypeEnum.value = res
      })
    })
    const toUrl = (item) => {
      const typeObj = {
        1: 'live',
        2: 'video',
        3: 'audio',
        4: 'graphic',
        5: 'subject',
        6: 'topic'
      }
      return `/course/${typeObj[(item.courseType || item.goodsType)]}?id=${item.id || item.goodsId}`
    }

    return {
      shopData,
      courseTypeEnum,
      toUrl
    }
  }
})
</script>
<style lang="scss" scoped>
.course_wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}
.course_title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  line-height: 22px;
  color: #333;
  margin-bottom: 20px;
}
.course_title_name {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 34px;
  img {
    width: 24px;
    height: 24px;
  }
}
.course_title_more {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
}
.more_img {
  margin-left: 4px;
}
.course_item_wrapper {
  margin-right: -10px;
  margin-left: -10px;
  .course_item_ad {
    width: 20%;
    padding-left: 10px;
    padding-right: 10px;
    .item_image_wrapper {
      height: 100%;
    }
  }
}

.course_item {
  width: 20%;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 15px;

}
// .course_item:nth-last-child(-n + 5) {
//   margin-bottom: 0px;
// }
.course_item_content {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 3px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    transition: all 0.3s;
  }
}

.course_type {
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 54px;
  height: 20px;
  background: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border-radius: 5px;
}

.item_image_wrapper {
  height: 124px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .item_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.course_desc {
  font-size: 12px;
  height: 95px;
  background-color: #fff;
  padding: 10px;
  .item_text {
    height: 40px;
    font-size: 16px;
    color:#333;
    line-height: 20px;
  }
  .course_detail {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .price {
    line-height: 20px;
    .current_price {
      font-size: 16px;
      font-weight: 550;
      color: #F61818;
      &.free{
        color: #58B85C;
      }
    }
    .current_price::before {
      content: '￥';
      font-size: 0.66em;
    }

    .free::before {
      display: none;
    }
    .old_price {
      margin-left: 5px;
      font-size: 12px;
      line-height: 18px;
      color: #999999;
      text-decoration-line: line-through;
    }
    .old_price::before {
      content: '￥';
      font-size: 1em;
    }
  }
  .course_sale {
    font-size: 12px;
    line-height: 18px;
    color: #999999;
  }
}
</style>
